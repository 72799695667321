import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AnswerListModel} from "../../../answer/models/answer-list.model";

@Injectable({
  providedIn: 'root'
})
export class CrudService<T> {

  ROUTE: string;

  constructor(
    protected http: HttpClient,
  ) {}

  async getOne (id: number, route: string, query = '') {
    return this.http.get(`${environment.BASE_PATH}/${route || this.ROUTE}/${id}?${query}`).toPromise();
  }

  async getMany (route: string, query = '') {
    return this.http.get(`${environment.BASE_PATH}/${route || this.ROUTE}?${query}`).toPromise();
  }

  async createOne (route: string, entity: any) {
    return this.createOneObservable(route || this.ROUTE, entity).toPromise();
  }

  createOneObservable (route: string, entity: any) {
    return this.http.post<any>(`${environment.BASE_PATH}/${route || this.ROUTE}`, entity);
  }

  async createMany(route: string, entities: any[]) {
    return this.http.post<any>(`${environment.BASE_PATH}/${route || this.ROUTE}`, {bulk: entities}).toPromise();
  }

  async deleteOne (route: string, entityId: number) {
    return this.deleteOneObservable(route || this.ROUTE, entityId).toPromise();
  }

  deleteOneObservable (route: string, entityId: number) {
    return this.http.delete<any>(`${environment.BASE_PATH}/${route || this.ROUTE}/${entityId}`);
  }

  async replaceOne(route: string, entity: any) {
    return this.replaceOneObservable(route || this.ROUTE, entity).toPromise();
  }

  replaceOneObservable(route: string, entity: any) {
    return this.http.put<any>(`${environment.BASE_PATH}/${route || this.ROUTE}/${entity.id}`, entity);
  }

  async getOneTypified(id: number, query: string = '') {
    return (await this.getOne(id, this.ROUTE, query) as T);
  }

  async getManyTypified(query: string = '') {
    const res = (await this.getMany(this.ROUTE, query) as any);
    return res?.data as T[];
  }

  async createOneTypified(entity: any) {
    return (await this.createOne(this.ROUTE, entity) as T);
  }

  async replaceOneTypified(entity: any) {
    return (await this.replaceOne(this.ROUTE, entity) as T);
  }
}
