import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApplicationInterceptor } from './services/Interceptor';
import { FullTableModule } from '@overflo-srl/full-table';
import { StateService } from './services/state.service';
import { BoardAuthService } from './services/board-auth/board-auth.service';
import { ActorService } from '../actor/services/actor/actor.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ActorEmployeeService } from './services/actor-employee/actor-employee.service';
import { ConstructionTableComponent } from './components/construction-table/construction-table.component';
import { ConstructionService } from '../construction/services/construction/construction.service';
import { SafePipe } from './pipes/safe/safe.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { ActorAddressBookComponent } from './components/actor-address-book/actor-address-book.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BtnCloseDialogComponent } from './components/btn-close-dialog/btn-close-dialog.component';
import { ActorTypePipe } from './pipes/actor-type/actor-type.pipe';
import { AlertMessagesBoxComponent } from './components/alert-messages-box/alert-messages-box.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ShowTagListComponent } from './tags/show-tag-list/show-tag-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HasRolePipe } from './pipes/has-role/has-role.pipe';
import { PwaInstallationDialogComponent } from './components/pwa-installation-dialog/pwa-installation-dialog.component';
import { PwaUpdateDialogComponent } from './components/pwa-update-dialog/pwa-update-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { PageScrollLinkDirective } from './directives/page-scroll-link/page-scroll-link.directive';
import { NgxFileDropModule } from 'ngx-file-drop';
import { OpenPdfDialogComponent } from './components/open-pdf-dialog/open-pdf-dialog.component';
import { SwiperWrapperComponent } from './components/swiper-wrapper/swiper-wrapper.component';
import { SortByPipe } from './pipes/sort-by/sort-by.pipe';
import {ChooseChipComponent} from "./components/choose-chip/choose-chip.component";
import { NotificationPermissionDialogComponent } from './components/notification-permission-dialog/notification-permission-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { ChooseChipObjectComponent } from './components/choose-chip-object/choose-chip-object.component';
import {PhotosFormComponent} from "./components/dynamic-form/photo/photos-form.component";
import {LoadPhotoBtnComponent} from "./components/dynamic-form/photo/load-photo-btn.component";
import {LoadedPhotosComponent} from "./components/dynamic-form/photo/loaded-photos.component";
import {DynamicFormComponent} from "./components/dynamic-form/dynamic-form.component";
import {BaseFormComponent} from "./components/dynamic-form/base-form.component";
import {SearchPipe} from "./pipes/shared.pipe";
import {MatTableResponsiveDirective} from "./directives/mat-table-responsive/mat-table-responsive.directive";
import {SimpleActorSoaList} from "./components/simple-actor-soa-list/simple-actor-soa-list.component";
import {ConstructionFiltersDialogComponent} from "./components/construction-filters-dialog/construction-filters-dialog.component";
import {ShareEntityListComponent} from "./components/share-entity-list/share-entity-list.component";
import {ChooseTagsComponent} from "./tags/choose-tags/choose-tags.component";
import {CustomTagDialogComponent} from "./tags/custom-tag-dialog/custom-tag-dialog.component";
import {CreateCustomTagComponent} from "./tags/create-custom-tag/create-custom-tag.component";
import { IconPipePipe } from './pipes/icon-pipe/icon-pipe.pipe';
import {ColorPickerComponent} from "./components/color-picker/color-picker.component";
import {IconPickerComponent} from "./components/icon-picker/icon-picker.component";
import {ChatTagComponent} from "./tags/chat-tag/chat-tag.component";
import {DialogClosingComponent} from "./components/dialog-closing/dialog-closing.component";
import {DisabledFieldComponent} from "./components/disabled-field/disabled-field.component";
import {AlertMessagesBoxDialogComponent} from "./components/alert-messages-box-dialog/alert-messages-box-dialog.component";
import {DateFilterExportDialogComponent} from "./components/date-filter-export-dialog/date-filter-export-dialog.component";
import { ConstructionCucListViewComponent } from './components/construction-cuc-list-view/construction-cuc-list-view.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ChipInputComponent} from "./components/chip-input/chip-input.component";
import {FilterDialogComponent} from "./components/filter-dialog/filter-dialog.component";
import {FilterDialogElementComponent} from "./components/filter-dialog-element/filter-dialog-element.component";
import {FilterDialogService} from "./services/filter-dailog/filter-dialog.service";
import {FirebaseNotificationService} from "./services/firebase-notification/firebase-notification.service";
import {LightsComponent} from "./components/lights/lights.component";
import {MobileStoreDialogComponent} from "./components/mobile-store-dialog/mobile-store-dialog.component";
import {RouterLinkListComponent} from "./components/router-link-list/router-link-list.component";
import {InfoDialogComponent} from "./components/info-dialog/info-dialog.component";

@NgModule({
    declarations: [
        PageNotFoundComponent,
        ConstructionTableComponent,
        SafePipe,
        SafeHtmlPipe,
        ActorTypePipe,
        NotificationDetailsComponent,
        BtnCloseDialogComponent,
        ActorTypePipe,
        AlertMessagesBoxComponent,
        ConfirmationDialogComponent,
        PwaInstallationDialogComponent,
        PwaUpdateDialogComponent,
        ShowTagListComponent,
        HasRolePipe,
        FooterComponent,
        ActorAddressBookComponent,
        SimpleActorSoaList,
        PageScrollLinkDirective,
        OpenPdfDialogComponent,
        SwiperWrapperComponent,
        SortByPipe,
        ChooseChipComponent,
        SearchComponent,
        ChooseChipObjectComponent,
        PhotosFormComponent,
        LoadPhotoBtnComponent,
        LoadedPhotosComponent,
        DynamicFormComponent,
        BaseFormComponent,
        SearchPipe,
        MatTableResponsiveDirective,
        NotificationPermissionDialogComponent,
        ConstructionFiltersDialogComponent,
        ShareEntityListComponent,
        ChooseTagsComponent,
        CustomTagDialogComponent,
        CreateCustomTagComponent,
        IconPipePipe,
        ColorPickerComponent,
        IconPickerComponent,
        ChatTagComponent,
        DialogClosingComponent,
        DisabledFieldComponent,
        DateFilterExportDialogComponent,
        AlertMessagesBoxDialogComponent,
        ChipInputComponent,
        FilterDialogComponent,
        FilterDialogElementComponent,
        ConstructionCucListViewComponent,
        LightsComponent,
        MobileStoreDialogComponent,
        InfoDialogComponent,
        RouterLinkListComponent,
    ],
  imports: [
    NgxUsefulSwiperModule,
    FlexLayoutModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    FullTableModule,
    MatDatepickerModule,
    MatMomentDateModule,
    FontAwesomeModule,
    InfiniteScrollModule,
    NgxFileDropModule,
    FormsModule,
    CommonModule,
    MatProgressBarModule,
  ],
    exports: [
        NgxUsefulSwiperModule,
        FlexLayoutModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        PageNotFoundComponent,
        ConstructionTableComponent,
        RouterModule,
        FullTableModule,
        SafePipe,
        SafeHtmlPipe,
        ActorTypePipe,
        NotificationDetailsComponent,
        MatDatepickerModule,
        MatMomentDateModule,
        BtnCloseDialogComponent,
        AlertMessagesBoxComponent,
        ShowTagListComponent,
        HasRolePipe,
        FontAwesomeModule,
        InfiniteScrollModule,
        NgxFileDropModule,
        FooterComponent,
        ActorAddressBookComponent,
        SimpleActorSoaList,
        PageScrollLinkDirective,
        OpenPdfDialogComponent,
        SwiperWrapperComponent,
        SortByPipe,
        ChooseChipComponent,
        SearchComponent,
        ChooseChipObjectComponent,
        SearchPipe,
        LoadPhotoBtnComponent,
        DynamicFormComponent,
        MatTableResponsiveDirective,
        ConstructionFiltersDialogComponent,
        ShareEntityListComponent,
        ChooseTagsComponent,
        CustomTagDialogComponent,
        IconPipePipe,
        ColorPickerComponent,
        IconPickerComponent,
        ChatTagComponent,
        DialogClosingComponent,
        DisabledFieldComponent,
        ChipInputComponent,
        FilterDialogComponent,
        CreateCustomTagComponent,
        ConstructionCucListViewComponent,
        LightsComponent,
        MobileStoreDialogComponent,
        InfoDialogComponent,
        RouterLinkListComponent,
    ],
  providers: [
    HasRolePipe,
    SearchPipe,
    ApplicationInterceptor,
    {provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true},
    DatePipe,
    StateService,
    BoardAuthService,
    ConstructionService,
    ActorService,
    ActorEmployeeService,
    DecimalPipe,
    FilterDialogService,
    FirebaseNotificationService,
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class SharedModule {}
