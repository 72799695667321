<mat-progress-bar *ngIf="isLoading && (error$ | async) === ''" mode="indeterminate"></mat-progress-bar>
<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <div class="login-header check-flex-col check-space-evenly check-items-center">
      <img src="../../../../assets/img/icons/logos/logo.png"/>
      <h1 style="margin-top: 20px"><a routerLink="/{{CommonRoutes.LOGIN}}">Il Portale del Cantiere</a></h1>
      <h2>{{pwaEnabled ? 'Entra nel tuo account' : 'Accedi interfaccia Cassa Edile'}}</h2>
      <span *ngIf="env != 'prod'" class="login-{{env}}">{{env}} BE: {{versionBackend}}, FE: {{versionFrontend}}</span>
    </div>
    <form [formGroup]="loginForm" class="login-form">
      <label>Username</label>
      <mat-form-field appearance="outline">
        <input autocomplete="off" formControlName="username" matInput type="text" />
      </mat-form-field>
      <br />
      <label>Password</label>
      <mat-form-field appearance="outline">
        <input autocomplete="off" formControlName="password" matInput type="password" />
      </mat-form-field>
      <mat-error class="login-error">{{ error$ | async }}</mat-error>
      <div class="w-full check-flex-row check-space-between check-items-center">
          <app-request-password-link class="check-flex check-items-center"></app-request-password-link>
        <br />
        <button color="primary" mat-flat-button (click)="login()" [disabled]="isLoading && (error$ | async) === ''">Login</button>
      </div>
      <div class="check-flex check-items-center mt-10">
        <a href="https://www.check-cantiere.it/page/registrati">Non hai ancora un account?</a>
        <a class="menu-item-container" href="https://www.youtube.com/watch?v=Kt7FYYQ5F1Y" target="_blank">
          <img class="youtube-icon" src="/assets/img/icons/youtube.svg" width="25" height="25" />
        </a>
      </div>
      <br />
      <div class="border center">
        <a href="https://www.check-cantiere.it" target="_blank">Sponsor | Per saperne di più</a>
      </div>
    </form>
  </div>
</div>
