import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getErrorMessage, isLoggedIn } from '../../selectors/auth.selector';
import { AppState } from '../../../reducers';
import { login } from '../../actions/auth.actions';
import { CookiePolicyComponent } from '../cookie-policy/cookie-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';
import { CookiesEnum } from '../../../shared/enums/cookies.enum';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics/google-analytics.service';
import { takeUntil } from 'rxjs/operators';
import { ChatService } from 'src/app/construction-chat/services/chat/chat.service';
import {CommonRoutes} from "../../../shared/enums/routes.enum";
import {environment} from "../../../../environments/environment";
import projectVersionJson from "../../../../../version.json";
import {CheckUtilsService} from "../../../shared/utils/check-utils.service";
import {StatusBarInfo} from "@capacitor/status-bar";
import {PlatformEnum} from "../../../shared/enums/platform.enum";
import {MobileService} from "../../../shared/services/mobile/mobile.service";
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  env = environment.env;
  pwaEnabled;
  versionFrontend = projectVersionJson.version;
  versionBackend = '';

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  isLoading = false;

  error$: Observable<string | undefined> | undefined;
  private readonly onDestroy = new Subject<void>();

  CommonRoutes = CommonRoutes;

  status: StatusBarInfo;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private chatService: ChatService,
    private checkUtilsService: CheckUtilsService,
    private mobileService: MobileService
  ) {}

  async ngOnInit() {
    this.status = await this.checkUtilsService.makeStatusBarOverlayFalse();

    this.pwaEnabled = environment.PWA_ENABLED;
    this.error$ = this.store.select(getErrorMessage);
    this.checkGoogleAnalyticsCookie();
    this.checkAndroidNotificationPermission();

    if (this.cookieService.get(CookiesEnum.TechnicalCookieKey) !== 'true') {
      const dialogRef = this.dialog.open(CookiePolicyComponent, {
        width: '50',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.checkGoogleAnalyticsCookie();
      });
    }

    this.chatService.logout();

    this.store
      .select(isLoggedIn)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.isLoading = false;
        }
      });

    this.versionBackend = await this.checkUtilsService.getVersionBackend();
  }

  checkGoogleAnalyticsCookie() {
    // this.googleAnalyticsService.loadScripts();
    // this.googleAnalyticsService.putTag();
  }

  checkAndroidNotificationPermission() {
    // da android 13 >= il consenso alle notifiche non viene attivato automaticamente
    const platform = PlatformEnum[Capacitor.getPlatform()];
    if(platform == PlatformEnum.android && !localStorage.getItem('first-run')) {
      const androidVersion = parseInt(this.mobileService.getAndroidVersion(), 10);
      if(androidVersion >= 13) {
        alert("Assicurati di avere le notifiche dell'applicazione abilitate.\n\nControlla da Impostazioni -> Notifiche -> Check");
        localStorage.setItem('first-run', 'first-run');
      }
    }
  }

  login() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.store.dispatch(
        login({
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
        })
      );
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
