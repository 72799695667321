import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArchiveDownloaderComponent} from "./components/archive-downloader/archive-downloader.component";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLinkWithHref} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {CanActivateDownloadArchiveGuard} from "./guards/download-archive-guard/download-archive.guard";
@NgModule({
  declarations: [
    ArchiveDownloaderComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterLinkWithHref,
    FormsModule,
    MatSlideToggleModule,
    SharedModule
  ],
  providers: [CanActivateDownloadArchiveGuard]
})
export class ArchiveModule { }
