import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BoardAuthService} from '../../../shared/services/board-auth/board-auth.service';
import {ActorModel} from '../../models/actor.model';
import {ResponseModel} from '../../../shared/models/response.model';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {RequestQueryBuilder} from '@nestjsx/crud-request';
import {PublicCertificationDto} from "../../dto/public-certification.dto";
import {UserModel} from "../../../user/models/user.model";
import {getActor} from "../../selectors/actor.selector";
import {take} from "rxjs/operators";
import {actorLoad} from "../../actions/actor.actions";
import {AppState} from "../../../reducers";
import {Store} from "@ngrx/store";
import {BaseUserService} from "../../../shared/services/base-user/base-user.service";
import {Social} from "../../my-data/enum/social.enum";
import {ActorWindowDto} from "../../window/dto/actor-window.dto";

@Injectable({
  providedIn: 'root',
})
export class ActorService {

  actorJoins = [{field: 'actorAtecos'}, {field: 'actorInps'}, {field: 'actorSoas'}, {field: 'board'}, {field: 'boardDelegated'}, {field: 'actorUser'}, {field: 'updatedByUser'}, {field: 'camComUpdatedByUser'}];

  constructor(
    private boardAuthService: BoardAuthService,
    private http: HttpClient,
    private store: Store<AppState>,
    private baseUserService: BaseUserService,
  ) {
  }

  getPrincipalAteco(actor: ActorModel) {
    for (const actorAteco of actor.actorAtecos || []) {
      if (actorAteco.atecoType == 'P' || actorAteco.atecoType == 'A') {
        return actorAteco;
      }
    }
    return null;
  }

  getSecondaryAtecos(actor: ActorModel) {
    const secondaryAtecos = [];
    for (const actorAteco of actor.actorAtecos || []) {
      if (actorAteco.atecoType != 'P' && actorAteco.atecoType != 'A') {
        secondaryAtecos.push(actorAteco);
      }
    }
    return secondaryAtecos;
  }

  create(actorModel: ActorModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${environment.BASE_PATH}/actor/`, actorModel);
  }

  update(actorId: string, actorModel: ActorModel): Observable<ResponseModel> {
    return this.http.patch<ResponseModel>(`${environment.BASE_PATH}/actor/` + actorId, actorModel);
  }

  updateEmail(actorId: number, email: string, constructionId: number): Observable<ActorModel> {
    return this.http.patch<ActorModel>(`${environment.BASE_PATH}/actor/${actorId}/email/${constructionId}`, {email});
  }

  async getWithRelations(actorId: number) {
    const qb = RequestQueryBuilder.create({
      join: [{field: 'actorSoas'}, {field: 'actorAtecos'}, {field: 'actorInps'}, {field: 'board'}, {field: 'boardDelegated'}, {field: 'actorUser'}, {field: 'updatedByUser'}, {field: 'camComUpdatedByUser'}],
    });

    if (actorId) {
      return await this.http.get<any>(`${environment.BASE_PATH}/actor/${actorId}?${qb.query()}`).toPromise();
    }
    return null;
  }

  async assignActorUndeletedRelations(actor: ActorModel) {
    actor.actorSoas = await this.http.get<any>(`${environment.BASE_PATH}/actor-soa/actor/${actor.id}`).toPromise();
    actor.actorAtecos = await this.http.get<any>(`${environment.BASE_PATH}/actor-ateco/actor/${actor.id}`).toPromise();
    return actor;
  }

  async getByIds(actorIds: number[]): Promise<ActorModel[]> {
    let url = `${environment.BASE_PATH}/actor/get-actors-by-ids`;
    return await this.http.post<ActorModel[]>(url, {ids: actorIds}).toPromise();
  }

  // async getByFiscalCode (fiscalCode: string) : Promise<any> {
  //   if(!fiscalCode) return null;
  //   const qb = RequestQueryBuilder.create({search: {fiscalCode: fiscalCode}, join: this.actorJoins});
  //   return (await this.http.get<any>(`${environment.BASE_PATH}/actor?${qb.query()}`).toPromise()).data[0];
  // }

  getByFiscalCodePromise(fiscalCode: string): Observable<ActorModel> {
    return this.http.get<any>(`${environment.BASE_PATH}/actor/get-actor-by-fiscalcode/${fiscalCode}`);
  }

  async getMany() {
    return await this.http.get<any>(`${environment.BASE_PATH}/actor/`).toPromise();
  }

  async getOne(id: number, query: string = '') {
    return await this.http.get<any>(`${environment.BASE_PATH}/actor/${id}?${query}`).toPromise();
  }

  getOneObservable(id: number, query: string = '') {
    return this.http.get(`${environment.BASE_PATH}/actor/${id}?${query}`);
  }

  async getActorSpp(actorId: number) {
    return this.http.get<ActorModel>(`${environment.BASE_PATH}/actor/${actorId}/spp`).toPromise();
  }

  async downloadImage(actorId: number) {
    return this.http.get(`${environment.BASE_PATH}/actor/${actorId}/image`, {responseType: 'blob'}).toPromise();
  }

  async deleteImage() {
    return this.http.post<ActorModel>(`${environment.BASE_PATH}/actor/image/delete`, {}).toPromise();
  }

  async uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<ActorModel>(`${environment.BASE_PATH}/actor/image/upload`, formData).toPromise();
  }

  publicCertificationQr(loggedUser: UserModel) {
    const urlDto = {url: `${environment.FRONTEND_PATH}/public/actor-certification/${loggedUser.baseActorId}/`};
    return this.http.post(`${environment.BASE_PATH}/actor/public-certification-qr`, urlDto, {
      responseType: "blob",
      observe: "response"
    });
  }

  getEmptyActor(): ActorModel {
    return {
      denomination: '',
      vat: '',
      email: '',
      fiscalCode: '',
      isCompany: false,
      boardId: null,
      boardDelegatedId: null,
      createdBy: null,
      updatedBy: null,
      actorInps: [],
      actorAtecos: [],
      actorSoas: [],
      fosUsers: [],
      actorUserId: null,
      emailUpdateUserId: null,
      city: '',
      address: '',
      zipCode: '',
      pec: '',
      phone: '',
      fax: '',
      mobilePhone: '',
      professionalAssociationName: '',
      professionalAssociationEntryNumber: '',
      lrDenomination: '',
      lrCity: '',
      lrAddress: '',
      lrZipCode: '',
      lrFiscalCode: '',
      lrProvince: '',
      lrEmail: '',
      lrPec: '',
      lrPhone: '',
      lrFax: '',
      lrCellularPhone: '',
      familiarsCount: 0,
      subordinatesCount: 0,
      familiarAndSubordinateUpdateQuarter: '',
      positionsCount: 0,
      rea: '',
      verified: false,
      actorType: 0,
      errorFiscalCode: false,
      errorVat: false,
      enabled: false,
      emailUpdateDate: null,
      durcStatus: '',
      durcExpirationDate: null,
      durcRequested: false,
      casuistry: '',
      created: new Date(),
      updated: new Date(),
      publicUrl: null,
      shortDescription: null,
      fullDescription: null,
      image: null,
      initialSource: '',
      version: 0,
      professionalAssociationProvince: '',
      extraData: null,
      wfas: null,
      doctorVerbalId: null,
      rsppVerbalId: null,
      rlsVerbalId: null,
      rlsActorEmployeeId: null,
      doctorFiscalCode: null,
      rssType: null,
      rssFiscalCode: null,
      rsppName: null,
      rlsType: null,
      rlsFiscalCode: null,
      rlsName: null,
      rlsChoice: null,
      province: '',
      alertEmail: '',
      camComUpdatedById: null,
      camComUpdatedDate: null,
      monetUpdateDate: null,
      activityState: null,
      constructionFundSub: [],
      turnover: null,
      lastBudgetYear: null,
      durcLastRequestDate: null,
      publicWindow: false,
      durcLastCheckDate: null,
    };
  }

  async publicCertificationData(publicCertificationToken: string): Promise<PublicCertificationDto> {
    return await this.http.get<PublicCertificationDto>(`${environment.BASE_PATH}/public-actor/${publicCertificationToken}/certification`).toPromise();
  }

  async loggedActor() {
    const actor = await this.store.select(getActor).pipe(take(1)).toPromise();
    if (actor) {
      return actor;
    } else {
      const loggedUser = await this.baseUserService.loggedUser();
      const loggedActor = await this.getOne(loggedUser.baseActorId);
      this.store.dispatch(actorLoad({actorModel: loggedActor}));
      return loggedActor;
    }
  }

  getShowMyWorkersOption(actor: ActorModel): boolean {
    if (actor.isCompany) return true;
    if (actor?.extraData) {
      const extraData = JSON.parse(actor.extraData);
      return extraData.show_my_workers;
    }
    return false;
  }

  getHideRequestedDocuments(actor: ActorModel): boolean {
    if (actor.isCompany) return false;
    if (actor?.extraData) {
      const extraData = JSON.parse(actor.extraData);
      return extraData.hide_requested_documents
    }
    return false;
  }

  getSocialId(actor: ActorModel | ActorWindowDto, social: Social) {
    if (!actor.social) return '';
    const data = JSON.parse(actor.social);
    return data[social];
  }

  async doesActorExistAndIsUserEnabled(fiscalCode: string) {
    return this.http.get<{ exists: boolean, isUserEnabled: boolean }>(`${environment.BASE_PATH}/actor/exists-and-is-related-user-enabled/${fiscalCode}`).toPromise();
  }
}
