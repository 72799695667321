import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {NotificationType} from "../../enums/notification.type.enum";
import {CheckRoutes} from "../../enums/routes.enum";
import {UserService} from "../../../user/services/user/user.service";
import {BaseUserService} from "../base-user/base-user.service";
import {ActorService} from "../../../actor/services/actor/actor.service";
import {ActorModel} from "../../../actor/models/actor.model";

export type NotificationDoc = {
  type: NotificationType,
  link: string,
  recipientUsers: string[],
  entityId?: number,
  entityName?: string;
  entityData?: any,
  createdAt: Date,
  createdBy: number,
  createdByUsername: string,
  createdByUID: string,
  createdByActor: number,
  createdByActorDenomination: string,
  message?: string,
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseNotificationService {

  constructor(
    private afs: AngularFirestore,
    private userService: UserService,
    private actorService: ActorService,
    private baseUserService: BaseUserService
  ) { }

  async addFirebaseNotification(notificationType: NotificationType, entity?: any, entityName?: string, recipientUsers?: string[], message?: string): Promise<any> {
    const loggedUser = await this.baseUserService.loggedUser();
    const loggedActor: ActorModel = await this.actorService.loggedActor()

    const docContent: NotificationDoc = {
      type: notificationType,
      link: this.setRedirectLinkByType(notificationType, entity),
      recipientUsers: await this.setRecipientUsersByType(notificationType, entity, recipientUsers),
      entityId: entity?.id,
      entityName: entityName,
      entityData: {...entity},
      createdAt: new Date(),
      createdBy: loggedUser?.id,
      createdByUsername: loggedUser?.username,
      createdByUID: loggedUser?.chatUID,
      createdByActor: loggedActor?.id,
      createdByActorDenomination: loggedActor?.denomination,
      message: message || '',
    }
    console.log('Sending notification to firebase...', docContent);

    return await this.afs.collection('notifications').add(docContent);
  }

  setRedirectLinkByType(notificationType: NotificationType , entity?: any) {
    try {
      switch (notificationType) {
        case NotificationType.TASK_COMPLETED:
        case NotificationType.TASK_ASSIGNED:
        case NotificationType.TASK_REASSIGNED:
          return `/${CheckRoutes.USER}/${CheckRoutes.ACTIVITY}/${entity.id}`;
        case NotificationType.TASK_DELETED:
          return `/${CheckRoutes.USER}/${CheckRoutes.ACTIVITY}`;
        case NotificationType.CHAT_CREATED:
          let webLink = `${CheckRoutes.USER}/${CheckRoutes.CONSTRUCTION}/${CheckRoutes.OFFICE}/${entity.constructionId}`;
          if (entity?.id) {
            webLink += `?group=${entity.id}`;
          }
          return `/${webLink}`;
        default:
          return '';
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  async setRecipientUsersByType(notificationType: NotificationType , entity?: any, users?: string[]) {
    if(users) {
      return users;
    }
    const recipientUsers: string[] = [];
    try {
      switch (notificationType) {
        case NotificationType.TASK_COMPLETED:
          const creator = await this.userService.getOne(entity.createdBy);
          recipientUsers.push(creator?.chatUID);
          break;
        case NotificationType.TASK_DELETED:
        case NotificationType.TASK_ASSIGNED:
        case NotificationType.TASK_REASSIGNED:
          const assignee = await this.userService.getOne(entity.assigneeUserId);
          recipientUsers.push(assignee?.chatUID);
          break;
      }
    } catch (e) {
      console.log(e);
      return [];
    }
    return recipientUsers;
  }
}
