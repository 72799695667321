export enum CrudOperator {
  EQUAL = '$eq',
  NOT_EQUAL = '$ne',
  GRATER = '$gt',
  LOWER = '$lt',
  GRATER_EQUAL = '$gte',
  LOWER_EQUAL = '$lte',
  OR = '$or',
  AND = '$and',
  STARTS = '$starts',
  CONTL = '$contL',
  IS_NULL = '$isnull',
  NOT_NULL = '$notnull',
  IN = '$in'
}
