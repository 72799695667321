<div class="dialog-header">
  <h2 mat-dialog-title>{{ data.dialogTitle }} <img src="../../../../assets/img/icons/logos/check-logo.svg" /></h2>
</div>

<mat-dialog-content class="mat-body" [innerHTML]="data.description"></mat-dialog-content>

<mat-dialog-actions align="end">
  <button
          color="primary" mat-flat-button (click)="window.open(platform == PlatformEnum.ios ? environment.APP_STORE_PATH :
          environment.GOOGLE_PLAY_PATH, '_blank')">{{ data.storeBtnLabel }}</button>
</mat-dialog-actions>
